import React, { useState } from "react";
import { graphql } from "gatsby";
import Layout from "../../components/shared/Layout";
import SEO from "../../components/SEO/SEO";
import Breadcrumbs from "../../components/shared/Breadcrumbs";
import PostTile from "../../components/shared/PostTile";
import "../../assets/styles/pages/category.scss";

const Category = (props) => {
  const {
    data: {
      allWpPost: { nodes },
      wpCategory: { name, seo},
    },
    pageContext: { uri, perPage },
  } = props;

  const createGroups = (pages, pageLength) =>
    pages.map((edge, index) => {
        return index % pageLength === 0
          ? pages.slice(index, index + pageLength)
          : null;
      }).filter(item => item);
  const groups = createGroups(nodes, perPage);
  const [items, setItems] = useState(groups[0]);
  const [pageIndex, setPageIndex] = useState(1);
  const loadMore = () => {
    setPageIndex(pageIndex + 1);
    setItems(items.concat(groups[pageIndex]));
  };
  return (
    <Layout>
      <SEO title={seo.title} description={seo.metaDesc} uri={uri}/>
      <Breadcrumbs breadcrumbs={seo.breadcrumbs} pageUrl={uri} pageTitle={seo.title}/>
      <div className={"wrapper"}>
        <h1>{name}</h1>
        <ul className={"category-list"}>
          {items && items.map((post, index) => {
            return (
              <PostTile key={index} post={post}/>
            );
          })}
        </ul>
      </div>
      {(groups.length > 1 && pageIndex <= groups.length - 1) &&
      <div className="loadmore-wrap">
        <button className="button"
                onClick={() => loadMore()}>
          <span>โหลดมากขึ้น...</span>
        </button>
      </div>
      }
    </Layout>
  );
};

export const query = graphql`
  query CategoryPageLoadmore(
    $userDatabaseId: Int
    $categoryDatabaseId: Int
  ) {
    wpCategory(databaseId: {eq: $categoryDatabaseId}) {
      name
      seo {
        breadcrumbs {
          text
          url
        }
        metaDesc
        title
        metaKeywords
      }
    }
    allWpPost(
      filter: {
        author: { node: { databaseId: { eq: $userDatabaseId } } }
        categories: {
          nodes: { elemMatch: { databaseId: { eq: $categoryDatabaseId } } }
        }
      }
      sort: { fields: date, order: DESC }
    ) {
      nodes {
        ...PostPreviewContent
      }
    }
  }
`;

export default Category;
